<template>
  <div class="main">
    <div class="banner"></div>
    <div class="add">
      <div class="content">
        <div class="badge">总部</div>
        <div class="text">
          <div class="bold">南京欣网互联网络科技有限公司</div>
          <div>
            <span>
              <img src="../assets/img/contact/contact_icon_1.png" alt="" />
              028-88888888</span
            >
            <span
              ><img
                src="../assets/img/contact/contact_icon_2.png"
                alt=""
              />江苏省南京市南昌路40号长江科技园2楼</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="content">
        <div class="info">
          <h2>山东部</h2>
          <div class="text">
            <div>
              <img src="../assets/img/contact/contact_icon_1.png" alt="" />
              028-88888888
            </div>
            <div>
              <img
                src="../assets/img/contact/contact_icon_2.png"
                alt=""
              />山东省济南市市中区大纬二路84号 德亨大厦9层
            </div>
          </div>
          <div class="btn"></div>
        </div>
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // let map = new window.AMap.Map("map", {
    //   zoom: 18, //级别
    //   center: [116.998606, 36.657339], //中心点坐标
    //   viewMode: "3D", //使用3D视图
    // });
    // let marker = new window.AMap.Marker({
    //   position: new window.AMap.LngLat(116.998406, 36.657439),
    //   title: "德亨大厦",
    // });
    // map.add(marker);
  },
};
</script>

<style lang="scss" scoped>

.text {
  font-size: 18px;
  line-height: 32px;
  color: #515151;
  margin-top: 19px;
  img {
    vertical-align: -9px;
    margin-right: 20px;
  }
  .bold {
    font-size: 28px;
    font-weight: bold;
    color: #313131;
  }
}
.banner {
  width: 100%;
  height: 900px;
  background: url("../assets/img/contact/banner_contact.jpg");
  background-size: 2560px 900px;
  background-position: center;
}
.add {
  height: 200px;
  overflow: hidden;
  .badge {
    margin-top: 44px;
    width: 60px;
    height: 36px;
    background-image: linear-gradient(45deg, #2397f3 0%, #1b65fb 100%);
    border-radius: 12px 12px 12px 0px;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  .text {
    display: flex;
    justify-content: space-between;

    span:first-child {
      margin-right: 60px;
    }
  }
}
.map {
  background: #f5f9fc;
  padding-top: 30px;
  padding-bottom: 60px;
  .content {
    height: 620px;
    background: url("../assets/img/contact/contact_shandong_bg.jpg") no-repeat
      100%;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    .info {
      margin: 68px 0 0 37px;
      .text {
        margin: 110px 0 85px 0;
        div:first-child{
            margin-bottom: 61px;
        }
      }
    }
    h2 {
      font-size: 30px;
      margin: 0;
    }
    .btn {
      width: 386px;
      height: 125px;
      background: url("../assets/img/contact/contact_shandong_button.png");
    }
    #map {
      width: 600px;
      height: 580px;
      background-color: #818181;
      box-shadow: -1px 2px 15px 0px rgba(61, 61, 61, 0.18);
      border: solid 1px #dedede;
      margin: 20px 20px 0 0;
    }
  }
}
</style>